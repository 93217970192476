import React, { FC } from "react";

const Landing: FC = () => (
    <div className='flex h-screen items-center bg-indigo-700'>
        <div className='max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8'>
            <h2 className='text-3xl font-extrabold text-white sm:text-4xl'>
                <span className='block'>Meet your new (class)mates</span>
                <span className='block'>using Semlink. 🏛️📚🥳</span>
            </h2>
            <p className='mt-4 text-lg leading-6 text-indigo-200'>
                Semlink helps you see who's in your Warwick teaching groups and
                lets you meet up more easily! A project by students from the
                University of Warwick{" "}
                <b>(but unaffiliated with the University)</b> coming in early
                2021.
            </p>
            {/* <button
                className='mt-8 md:mr-4 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto disabled:opacity-50 disabled:pointer-events-none'
                disabled>
                Sign In
            </button> */}
            <button
                className='mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto disabled:opacity-50 disabled:pointer-events-none'
                disabled>
                Sign Up (Closed Beta)
            </button>
        </div>
    </div>
);

export default Landing;
