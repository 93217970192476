import React from "react";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import Landing from "./views/Welcome";

const App = () => (
    <Router>
        <Switch>
            <Route path='/' exact>
                <Landing />
            </Route>
            <Route>
                <Redirect to='/' />
            </Route>
        </Switch>
    </Router>
);

export default App;
